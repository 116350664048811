import { Col, Grid, Loading } from "@nextui-org/react";
import { useHits } from "react-instantsearch-hooks-web";
import { useState } from "react";
import useApi from "@/components/useApi";
import ManagementInterfaceTable from "./ManagementInterfaceTable/ManagementInterfaceTable";

const ManagementCharacterHits = (props) => {
  const { hits } = useHits(props);
  const [data, setData] = useState([]);
  const { jsonApiService, callApi, isLoading } = useApi();
  const { callApi: callDeleteApi, isLoading: isDeleteLoading } = useApi();



  const deleteCharacter = async (id) => {
    await callDeleteApi(jsonApiService.deleteResource, `admin/characters/${id}`).then(
      () => {
        //this API isn't in functional
        callApi(jsonApiService.fetchResource, "admin/characters").then(
          (resourceData) => {
            setData(resourceData.data);
          }
        )
      }
    )
  };

  if (isLoading || isDeleteLoading) return <Loading />;
  if (!data) return null;

  return (
    <Grid.Container gap={1} justify="flex-start">
      <Col>
        <ManagementInterfaceTable data={hits} onDelete={deleteCharacter} />
      </Col>
    </Grid.Container>
  );
};

export default ManagementCharacterHits;

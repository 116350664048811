import { configureStore } from '@reduxjs/toolkit';
import userReducer, { setKindeUser, setProfile } from '@/features/user/userSlice';

import jsonApiService from '@/services/jsonApiService';
import { TrackJS } from "trackjs";

const TrackJSLogger = store => next => action => {
  //if(!window.TrackJs) return next(action );
  try {
    // log every action so they appear in the TrackJS telemetry timeline
    TrackJS.console.log(action);
    //console.log(action)
    switch (action.type) {
      case 'user/setProfile':
        TrackJS.addMetadata("userId", action.payload?.id);
        break;
    }
    return next(action)
  } catch (err) {
    // Something bad happened, lets log out the entire state so we can see it in the timeline
    console.warn(store.getState());

    // NOTE: this assumes TrackJS was initialized previously, at app startup.
    TrackJS.track(err);
  }
}


const userMiddleware = (store) => (next) => async (action) => {
  action = next(action);

  //Persist the guest user id in local storage
  const state = store.getState();

  if (setKindeUser.match(action)) {
    const kindeUser = state.user.kindeUser

    try {
      if (kindeUser?.token && kindeUser?.id) {
        jsonApiService.setUserToken(kindeUser.token);
        let result = await jsonApiService.fetchResource('users').catch(
          async () => {
            return await jsonApiService.createResource('users', { ...kindeUser })
          }
        )
        if (!result?.id) {
          result = await jsonApiService.createResource('users', { ...kindeUser })
        }
        store.dispatch(setProfile(result));
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }

  return action
};
const store = configureStore({
  reducer: {
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(TrackJSLogger)
    .concat(userMiddleware)
});


export default store;

import MetaTags from "@/components/common/MetaTags";
import ScrollBackToTop from "@/components/common/ScrollBackToTop";
import ManagementCharacterListing from "../components/managementInterface/ManagementCharacterListing";

export default function HomePage() {

  return (
    <div>
        <MetaTags meta={{title:"Manage Characters - SpicyChat Admin"}}/>
      <ManagementCharacterListing/> 
      <ScrollBackToTop/>
    </div>
  );
}

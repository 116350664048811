import { Outlet } from "react-router-dom";
import { Container} from "@nextui-org/react";
import { PropTypes } from "prop-types";
import { NavigationBar } from "@/components/navbar/NavigationBar";
import AccountRequiredModal from "@/routes/AccountRequiredModal";
import Footer from "@/components/Footer";


const Layout = ({hideFooter=false,isProtected=false}) => {
  return (
    <><div style={{minHeight:"100vh"}}>
      <NavigationBar />
      <Container gap={1} >
        <Outlet />
      {isProtected && <AccountRequiredModal/>}
      </Container>
      </div>
      {!hideFooter && <Footer/>}
    </>
  );
};
 
Layout.propTypes = {
  hideFooter: PropTypes.bool,
  isProtected: PropTypes.bool,
  useWaitingRoom: PropTypes.bool,
};
export default Layout;

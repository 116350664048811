import { useEffect, Suspense} from 'react';
import { TrackJS } from "trackjs";
import {
  RouterProvider,
  createBrowserRouter,
  Outlet,
  useRouteError,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Loading } from "@nextui-org/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useDispatch, useSelector } from "react-redux";

import Layout from "./Layout";
import ScrollToTop from "./ScrollToTop";
import HomePage from "./HomePage";

import { setKindeUser } from "@/features/user/userSlice";
import NotFoundPage from './NotFoundPage';
import NotAllowedPage from './NotAllowedPage';
import ManageCharactersPage from './ManageCharactersPage';
import ManageReportedCharactersPage from './ManageReportedCharactersPage';


const RootErrorBoundary = () => {
  let error = useRouteError();
  if(window.TrackJS) TrackJS.track(error);
  return (
    <div>
      <h1>Uh oh, something went terribly wrong 😩</h1>
      <pre>{error.message || JSON.stringify(error)}</pre>
      <button onClick={() => (window.location.href = "/")}>
        Click here to reload the app
      </button>
    </div>
  );
};
export function Fallback() {
  return <p>Loading</p>;
}

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthenticated, user: kindeUser, getToken } = useKindeAuth();
  const { profile, isProfileLoaded } = useSelector((state) => state?.user);

  useEffect(() => {
    if (typeof document !== "undefined" && location.pathname == "/") {
      document.body.style.overflow = "inherit";
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthenticated && !isProfileLoaded) {
      getToken().then((token) => {
        dispatch(setKindeUser({ ...kindeUser, token: token }));
      });
    }
    if (isAuthenticated && isProfileLoaded && !profile?.username) {
      navigate("/signup");
    }
  }, [
    isAuthenticated,
    kindeUser,
    navigate,
    getToken,
    dispatch,
    profile,
    isProfileLoaded,
  ]);
  return (
    <ScrollToTop>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </ScrollToTop>
  );
};

let router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
         {
        element: <Layout isProtected={true}/>,
        children: [
          { path: "/", element: <HomePage /> },
          { path: "/characters", element: <ManageCharactersPage /> },
          { path: "/reviews", element: <ManageReportedCharactersPage />},          
        
        ],
      },
      {
        element: <Layout />,
        children: [
          { path: "*", element: <NotFoundPage /> },
          { path: "/not-allowed", element: <NotAllowedPage /> },
          {
            path: "",
            element: <Outlet />,
            errorElement: <RootErrorBoundary />,
            children: [],
          },
        ],
      },
      ],
  },
]);
if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

const Routes = () => {
  return (
    <RouterProvider router={router} fallbackElement={<Fallback />}>
      <App />
    </RouterProvider>
  );
};
export default Routes;

import { Input} from "@nextui-org/react";
import { useSearchBox } from 'react-instantsearch-hooks-web';

const ManagementSearchBox = (props) => {
  const { refine} = useSearchBox(props);
  

  const handleInputChange = (e) => {
    refine(e.target.value);
  };

  return (
    <>
      <Input
        clearable
        fullWidth
        size="lg"
        placeholder="Search..."
        onChange={handleInputChange}
        aria-labelledby="search-characters"
      />
    </>
  );
};


export default ManagementSearchBox;

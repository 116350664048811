import { useState } from "react";
import PropTypes from "prop-types";
import algoliasearch from "algoliasearch/lite";
import 'instantsearch.css/themes/satellite.css';
import { InstantSearch, Configure, Stats } from "react-instantsearch-hooks-web";
import { history } from "instantsearch.js/es/lib/routers";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Text, Spacer } from "@nextui-org/react";
import ManagementToggleRefinement from "./ManagementToggleRefinement";
import ManagementSortBy from "./ManagementSortBy";
import ManagementSearchBox from "./ManagementSearchBox";
import ManagementCharacterHits from "./ManagementCharacterHits";
import ManagementAlgoliaPagination from "./ManagementAlgoliaPagination";

const appId = import.meta.env.VITE_ALGOLIA_APP_ID;
const apiKey = import.meta.env.VITE_ALGOLIA_SEARCH_KEY;
const searchClient = algoliasearch(appId, apiKey);

const ManagementCharacterListing = ({
  indexName,
  initialSearchState,
  onSearchStateChange,
}) => {
  const [searchState, setSearchState] = useState(initialSearchState);
  const navigate = useNavigate();

  const setNextSearchState = (nextSearchState) => {
    setSearchState(nextSearchState);
    onSearchStateChange(nextSearchState);
  };

  const sortItems = [
    { value: indexName, label: "Popular" },
    { value: `${indexName}_latest`, label: "Latest" },
    { value: `${indexName}_alphabetical`, label: "Name" },
  ];

  const routing = {
    router: history({
      push(url) {
        const urlObject = new URL(url);
        const params = new URLSearchParams(urlObject.search);
        //We exclude nsfw from routing
        params.delete("characters[toggle][is_nsfw]");
        const newUrl = urlObject.pathname + "?" + params.toString();
        navigate(newUrl);
      },
      getLocation() {
        return window.location;
      },
    }),
  };
  return (
    <InstantSearch
    searchClient={searchClient}
    indexName={indexName}
    searchState={searchState}
    routing={routing}
    onSearchStateChange={setNextSearchState}
  >
    <Configure hitsPerPage={48} />
    
    <Text h3>Manage Characters</Text>
    <Container gap={0}>
      <Row>
        <Col>
          <Row justify="flex-start" align="center">
            <ManagementSearchBox currentRefinement={indexName} />
            <Spacer x={0.5} />
            <ManagementToggleRefinement attribute={"is_underage"} label="Underage" />
            <ManagementToggleRefinement attribute={"is_nsfw"} label="NSFW" />
            
            <Spacer x={0.5} />

            <ManagementSortBy items={sortItems} initialIndex={indexName} />
          </Row>
        </Col>
      </Row>
      
      
    </Container>
    <Row css={{ ml: "10px" }}>
      <Text i size={12}>
        <Stats />
      </Text>
    </Row>
    <ManagementCharacterHits />
    <Spacer y={1}/>
      <Row justify="center"><ManagementAlgoliaPagination /></Row>
  </InstantSearch>
  );
};

ManagementCharacterListing.propTypes = {
  indexName: PropTypes.string,
  children: PropTypes.node,
  linkedStoryGroup: PropTypes.string,
  hasPlayground: PropTypes.bool,
  initialSearchState: PropTypes.object,
  onSearchStateChange: PropTypes.func,
};

ManagementCharacterListing.defaultProps = {
  indexName: import.meta.env.VITE_ALGOLIA_CHARACTER_INDEX,
  initialSearchState: {},
  onSearchStateChange: (searchState) => searchState,
};

export default ManagementCharacterListing;

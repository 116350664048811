import { Table, Row, Col, User, Text} from "@nextui-org/react";
import { getAvatarUrl } from "@/libs/utils";
import PropTypes from "prop-types";
import {AiFillEdit as EditIcon} from "react-icons/ai";
import { useState, useEffect } from 'react'
import EditModal from "../../EditModal/EditModal";
import ModerationActions from "../../ModerationsActions";


export default function ManagementInterfaceTable({ data, }) {
  const [visible, setVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [stateData, setStateData] = useState(data);


  useEffect(() => {
    setStateData(data)
  }, [data])
  const editHandler = (id) => {
    setEditId(id)
    setVisible(true);
  }

  const onModerationComplete = async ({id}) => {
    setStateData((prevData)=>[...prevData].filter((item)=>item.id!==id))
  }
    

  const columns = [
    { name: "Name", key: "name" ,maxWidth:"20px"},
    { name: "Title", key: "title" },
    { name: "Creator", key: "creator_username" },
    { name: "Visibility", key: "visibility" },
    { name: "ACTIONS", key: "actions" },
  ];

  const renderCell = (character, columnKey) => {
    const cellValue = character[columnKey];
    switch (columnKey) {
      case "name":
        return (
          <Col justify='left' css={{mw:220}}>
          <User
            squared
            size="xl"
            onClick={() => editHandler(character.id)}
            src={getAvatarUrl(character.avatar_url, {
              width: 64,
              height: 64,
              focus: "top",
            })}
            name={cellValue}
            css={{ p: 0 }}
          ></User>
          </Col>
          
        );

      case "actions":
        return (
          <Row justify="center" align="center">
            <Col css={{ d: "flex" }}>
              <EditIcon cursor={"pointer"} onClick={() => editHandler(character.id)} size={20} fill="#979797" />
            </Col>
            <ModerationActions character_id={character.id} onComplete={onModerationComplete}/>
          </Row>
        );
      default:
        return <Text>{cellValue}</Text>;
    }
  };
  return (
    <>
      <EditModal editId={editId} closeHandler={()=>setVisible(false)} visible={visible} />
      <Table
        aria-label="Management interface table"
        css={{
          height: "auto",
          minWidth: "100%",
        }}
        selectionMode="none"
      >
        <Table.Header columns={columns}>
          {(column) => (
            <Table.Column
              css={{ fontWeight: "bold", color: "#fff", w:column.maxWidth }}
              key={column.key}
              //hideHeader={column.key === "actions"}
              align={column.key === "actions" ? "center" : "start"}
            >
              {column.name}
            </Table.Column>
          )}
        </Table.Header>
        <Table.Body items={stateData}>
          {(item) => (
            <Table.Row>
              {(columnKey) => (
                <Table.Cell css={{p:0,whiteSpace:"unset"}}>{renderCell(item, columnKey)}</Table.Cell>
              )}
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}

ManagementInterfaceTable.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    visibility: PropTypes.string.isRequired,
    creatorUsername: PropTypes.string.isRequired,
    creatorUserId: PropTypes.string.isRequired,
    greeting: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    numMessages: PropTypes.number.isRequired,
    isUnderage: PropTypes.bool.isRequired,
    isNsfw: PropTypes.bool.isRequired,
  }).isRequired,
  onDelete: PropTypes.func
};

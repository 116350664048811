import { useEffect, useState } from "react";
import {  Text,  Table,User, Row, Col,  Tooltip, styled} from "@nextui-org/react";
import { Loading } from "@nextui-org/react";
//import { useNavigate } from "react-router-dom";
import useApi from "@/components/useApi";
import MetaTags from "@/components/common/MetaTags";
import CharacterCardSmall from "@/components/Character/CharacterCardReviews";

import {AiFillEdit as EditIcon} from "react-icons/ai";
import {GoIssueClosed as CloseIcon} from "react-icons/go"
import ModerationActions from "../components/ModerationsActions";
import EditModal from "../components/EditModal/EditModal";

const IconButton = styled('button', {
    dflex: 'center',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '0',

    paddingLeft: '2px',
    margin: '0',
    bg: 'transparent',
    transition: '$default',
    '&:hover': {
      opacity: '0.8'
    },
    '&:active': {
      opacity: '0.6'
    }
  });

export default function ManageReportedCharactersPage() {
  const [data, setData] = useState([]);
  const { jsonApiService, callApi, isLoading } = useApi();
  const { callApi: callActionApi } = useApi();
  const [visible, setVisible] = useState(false);
  const [editId, setEditId] = useState("");

  const editHandler = (id) => {
    setEditId(id)
    setVisible(true);
  }

  const closeHandler = () => {
    setVisible(false);
  };

  /////const navigate = useNavigate();


  useEffect(() => {
    async function fetchData() {
      const resourceData = await callApi(
        jsonApiService.fetchResource,
        "admin/reviews?filters={\"status\":\"open\"}"

      );
      //let sorted=resourceData.data.sort((a,b)=>a.character.name.localeCompare(b.character.name))
      let sorted=resourceData.data.sort((a,b)=>a.createdAt < b.createdAt ? 1 : -1)
      setData(sorted);
    }
    fetchData();
    return () => setData(null);
  }, [callApi, jsonApiService.fetchResource]);

  const closeReport = async (id,action_taken='none') => {
    //remove that item form the data
    setData((prevData)=>[...prevData].filter((item)=>item.id!==id))
    await callActionApi(jsonApiService.updateResource, 'admin/reviews',id,{"status":"closed", "action_taken":action_taken});    
  };

  const onComplete = async ({action, id}) => {
    let reports=[...data].filter((item)=>item.character_id===id)
    reports.forEach((report)=>{
      closeReport(report.id,action)
    })
  }
    
  
  const columns = [
    { name: "CHARACTER", uid: "character_id" },
    { name: "ISSUE", uid: "issue" },
    { name: "RESOLUTION", uid: "resolution" },
    { name: "DETAILS", uid: "details"},
    { name: "ACTIONS", uid: "actions" },
  ];
  
  const renderCell = (r, columnKey) => {
    const cellValue = r[columnKey];
    switch (columnKey) {
      case "character_id":
        return <Row css={{mb:10}}><CharacterCardSmall  character={r.character} extraStyles={r?.character?.is_deleted?{bg:"red"}:{}}/></Row>
      case "name":
        return (
          <User squared src={r.avatar} name={cellValue} css={{ p: 0 }}>
            {r.email}
          </User>
        );
      case "actions":
        return (
          <Row justify="center" align="center">
            <Col css={{ d: "flex" }}>
              <Tooltip content="Edit character">

                <IconButton onClick={() => editHandler(r.character_id)} >
                  <EditIcon size={20} fill="#979797" />
                </IconButton>
              </Tooltip>
            </Col>
            <ModerationActions character_id={r.character_id} onComplete={onComplete}/>

            <Col css={{ d: "flex" }}>
              <Tooltip
                content="Close Report"
                onClick={() => closeReport(r.id)}
              >
                <IconButton>
                  <CloseIcon size={20}/>
                </IconButton>
              </Tooltip>
            </Col>

          </Row>
        );
      case "details":
        return <Row style={{ width:"400px"}}><Text size={12}>{cellValue}</Text></Row>

      default:
        return cellValue;
    }
  };

  if (isLoading ) return <Loading />;
  if ((data||[]).length >0)
  return (
    <>
      <MetaTags meta={{title:"Reported Characters"}} />
      <EditModal editId={editId} closeHandler={closeHandler} visible={visible} />
      <h3>Reported Issues</h3>
      <Table
      aria-label="Example table with custom cells"
      css={{
        height: "auto",
        minWidth: "100%",
      }}
      selectionMode="none"
    >
      <Table.Header columns={columns}>
        {(column) => (
          <Table.Column
            key={column.uid}
            hideHeader={column.uid === "actions"}
            align={column.uid === "actions" ? "center" : "start"}
          >
            {column.name}
          </Table.Column>
        )}
      </Table.Header>
      <Table.Body items={data}>
        {(item) => (
          <Table.Row>
            {(columnKey) => (
              <Table.Cell css={{p:0,whiteSpace:"unset"}}>{renderCell(item, columnKey)}</Table.Cell>
            )}
          </Table.Row>
        )}
      </Table.Body>
    </Table>
    
    </>
  );
}

import ReactDOM from 'react-dom/client';
import { TrackJS } from 'trackjs';
import Routes from './routes';
import { NextUIProvider, createTheme } from '@nextui-org/react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { KindeProvider } from '@kinde-oss/kinde-auth-react';
import { Provider } from 'react-redux';

import ReactGA from "react-ga4";

import store from './store';
import './index.css';


ReactGA.initialize("G-N38T5KLH16");
setTimeout(() => {
  if (window?.Beamer) window.Beamer.init()
}, 2000);

//if (location.host.indexOf("localhost") !== 0) {
  TrackJS.install({
    token: import.meta.env.VITE_TRACKJS_TOKEN,
    application: import.meta.env.VITE_TRACKJS_APPLICATION,
  });
//}
const lightTheme = createTheme({
  type: 'light',
  theme: {
    colors: {},
  },
});

const darkTheme = createTheme({
  type: 'dark',
  theme: {
    colors: {},
  },
});


ReactDOM.createRoot(document.getElementById('root')).render(
  <KindeProvider
    clientId={import.meta.env.VITE_KINDE_CLIENT_ID}
    domain={import.meta.env.VITE_KINDE_DOMAIN}
    logoutUri={window.location.origin}
    redirectUri={window.location.origin}
    isDangerouslyUseLocalStorage={import.meta.env.VITE_KINDE_USE_LOCAL_STORAGE === 'true'}
    onRedirectCallback={(user, appState) => {
      if (appState?.redirectTo) {
        window.location = appState?.redirectTo;
      }
    }}
  >
    <Provider store={store}>
      <NextThemesProvider
        defaultTheme='system'
        attribute='class'
        value={{
          light: lightTheme.className,
          dark: darkTheme.className,
        }}
      >
        <NextUIProvider theme={darkTheme}>
    
          <Routes />
        </NextUIProvider>
      </NextThemesProvider>
    </Provider>
  </KindeProvider>
);

import MetaTags from "@/components/common/MetaTags";

export default function NotFoundPage() {
  return (
    <div id="error-page">
      <MetaTags index="NoFoundPage"/>
      <h1>NotFoundPage</h1>
    </div>
  );
}

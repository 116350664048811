  import { Modal, Button, Text, Row, Col} from "@nextui-org/react";
  import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
  import { useNavigate } from "react-router-dom";
  
  const AccountRequiredModal = () => {
    const navigate = useNavigate();
    const { register, isAuthenticated, login,isLoading } = useKindeAuth();
    let visible = !isAuthenticated && !isLoading;

    const onCloseHandler=()=>{
      navigate('/not-allowed');
    }
    return (
      <>
        {visible && (
           <Modal
           closeButton
           blur
           aria-labelledby="modal-title"
           open={visible}
            onClose={()=>onCloseHandler()}
           
         >
           <Modal.Header>
           <Text b size={18}>

          Register to SpicyChat
          </Text>
          
           </Modal.Header>
           <Modal.Body >
            <Col align="center">
           <Text id="modal-title" size={18}>
               Login or Create Your Account to talk to or create your Characters. Registration is free.
             </Text>
             </Col>
           </Modal.Body>
           <Modal.Footer >
            <Row>
            <Col align="center">
             <Button flat auto onPress={()=>register()}>
               Sign Up
             </Button>
              </Col>
              <Col align="center">
             <Button flat auto onPress={()=>login()}>
               Login
             </Button>
             </Col>
             </Row>
           </Modal.Footer>
         </Modal>
        )}
      </>
    );
  };
  
  AccountRequiredModal.propTypes = {

  };
  
  export default AccountRequiredModal;
  
import { Card, Text, Link, Row, Col, Tooltip } from "@nextui-org/react";

import PropTypes from "prop-types";
import { getAvatarUrl } from "@/libs/utils";
import MessageCounter from "./MessageCounter";
import { RiGitRepositoryPrivateLine as PrivateIcon } from "react-icons/ri";
import { BsKey as HiddenIcon } from "react-icons/bs";
import {MdNoAdultContent as NoAdultIcon} from "react-icons/md"



const CharacterCardReviews = ({ character={}, onPress,extraStyles }) => {
  return (
    <Link onClick={onPress}>
      <Card variant="bordered" css={{ w: "400px", ...extraStyles}} gap={0}>
        <Card.Body css={{m:0,p:0}}>
            <Row>
            <Col justify="flex-start" css={{w:'70px'}}>
            <Card.Image width={60}
            src={getAvatarUrl(character?.avatar_url, {
              width: 60,
              height: 60,
              focus: "auto",
            })}
            objectFit="cover"
            css={{ borderRadius: "10px" }}
            alt={character?.name}
          />
            
            </Col>
            <Col>
            <Row justify="flex-start"><Text b size={16}>{character?.name}</Text></Row>
          <Text size="$sm" small>{character.title}</Text>
            </Col>
            </Row>

        </Card.Body>
        <Card.Divider />
        <Card.Footer css={{ p: 0 }}>
          <Row css={{ ml: "5px", mr: "5px" }} align="center">
            <Col><Text i size="$xs">@{character.creator_username}</Text>
            </Col>
            <Col >
              <Row justify="flex-end" align="center"><MessageCounter num_messages={character.num_messages} />
              {character.visibility === "private" && <PrivateIcon/>}
          {character.visibility === "hidden" && <HiddenIcon />}
          {character?.is_underage && <Tooltip content="This character is possibly underage" placement="topStart"><Text size="$md">🔞</Text></Tooltip>}
          {!character?.is_nsfw && <NoAdultIcon/>}

              </Row>

            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Link>

  );
};

CharacterCardReviews.propTypes = {
  character: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    avatar_url: PropTypes.string,
    creator_user_id: PropTypes.string,
    creator_username: PropTypes.string,
    num_messages: PropTypes.number,
    visibility: PropTypes.string,
    is_underage: PropTypes.bool,
    is_nsfw: PropTypes.bool,
  },
  ),
  onPress: PropTypes.func,
  onDeleteCharacter: PropTypes.func,
  hideOptions: PropTypes.bool,
  extraStyles: PropTypes.object,
};

export default CharacterCardReviews;

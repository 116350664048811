import { Pagination } from "@nextui-org/react";
import { usePagination } from 'react-instantsearch-hooks-web';

const ManagementAlgoliaPagination = (props) => {
    const {
      currentRefinement,
      nbPages,
      canRefine,
      refine,
    } = usePagination(props);
  
  const handleNextClick = (e) => {
    refine(e-1);
  };

  if(!canRefine) return null;
  return (
    <>
      <Pagination
        onChange={(e) => handleNextClick(e)}
        total={nbPages}
        initialPage={currentRefinement+1}
      />
    </>
  );
};


export default ManagementAlgoliaPagination;

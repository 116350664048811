import { Dropdown } from "@nextui-org/react";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSortBy } from 'react-instantsearch-hooks-web';

const ManagementSortBy = (props) => {
  const {
    initialIndex,
    currentRefinement,
    options,
    refine,
    items,
  } = useSortBy(props);

  const [selected, setSelected] = useState(new Set([initialIndex]));

  const getLabel = (value) => {
    const option = options.find(option => option.value === value);
    return option ? option.label : null;
}  
  return (
    <>
      <Dropdown>
        <Dropdown.Button flat> {getLabel(currentRefinement)}</Dropdown.Button>
        <Dropdown.Menu
          aria-label="sort-by"
          items={items}
          disallowEmptySelection
          selectedKeys={selected}
          onSelectionChange={setSelected}
          onAction={(event) => {
            refine(event);
          }}
        >
          {options.map((option) => (
            <Dropdown.Item key={option.value} value={option.value}>
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

ManagementSortBy.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  refine: PropTypes.func,
  defaultRefinement: PropTypes.string,
};

export default ManagementSortBy;

import { Text } from "@nextui-org/react";
import PropTypes from "prop-types";
import { BiEnvelope as MessageIcon } from "react-icons/bi";
import {formatNumber} from "@/libs/utils";
const MessageCounter = ({num_messages})  => {
    
    if(!num_messages) return null;
    return (
        <>
        <span style={{fontSize: "10px", verticalAlign: "middle", display: "flex",
    alignItems: "center"}}>
            <MessageIcon/><Text size={11}>{formatNumber(num_messages)}</Text></span>
      
      </>
    );
    }
MessageCounter.propTypes = {
    num_messages: PropTypes.number,
    };

export default MessageCounter;

import MetaTags from "@/components/common/MetaTags";
import {Link, Text } from  "@nextui-org/react";

export default function HomePage() {

  return (
    <div>
      <MetaTags meta={{title:"SpicyChat Admin"}}/>
      <Text h2>SpicyChat Management</Text>
      <Text h3>Manage Characters</Text>
      <li><Link href="/characters">Manage All Characters</Link></li>
      <li><Link href="/reviews">Reported Characters</Link></li>
    </div>
  );
}

import { useEffect, useState } from "react";
import { Row, Switch, Text } from "@nextui-org/react";
import { useToggleRefinement } from "react-instantsearch-hooks-web";
import PropTypes from "prop-types";

const ManagementToggleRefinement = (props) => {
  const { attribute, label, disableOff=false } = props;

  const [isRefined, setIsRefined] = useState(false);
  const { refine } = useToggleRefinement({
    attribute,
    on: isRefined,
    off: disableOff?null:isRefined,
  });

  useEffect(() => {
    const storedValue = localStorage.getItem(`${label}Toggle`);
    if (storedValue !== null) {
      setIsRefined(storedValue === "true");
      refine(storedValue === "true");
    }
  }, [label]);

  const handleToggle = () => {
    const newValue = !isRefined;
    setIsRefined(newValue);
    // refine(newValue);
    localStorage.setItem(`${label}Toggle`, String(newValue));
  };

  return (
    <>
      <Row justify="flex-end" align="center" gap={1.6}>
        <Switch
          size="xs"
          checked={isRefined}
          onChange={handleToggle}
          style={{ marginRight: "5px" }}
        />
        <Text size={14}>{label}</Text>
      </Row>
    </>
  );
};

ManagementToggleRefinement.propTypes = {
  attribute: PropTypes.string,
  label: PropTypes.node,
  disableOff: PropTypes.bool,
};

export default ManagementToggleRefinement;

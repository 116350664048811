import { Container, Row, Link, Col,Button} from "@nextui-org/react";
import {BsDiscord as DiscordIcon} from "react-icons/bs"
import {BsReddit as RedditIcon} from "react-icons/bs"

const Footer = () => {
  return (

      <Container justify="center" align="center" css={{mt:"20px"}}>
        <Row justify="center" css={{w:"400px"}}>
            <Col>
            <Link target="_blank" href="https://discord.gg/ruexExw2nr"><Button icon={<DiscordIcon size="20px" css={{pr:"20px"}}/>} auto light>Join us on Discord</Button></Link>
            </Col><Col>
            <Link target="_blank" href="https://www.reddit.com/r/SpicyChatAI/"><Button icon={<RedditIcon size="20px"/>} auto light>Reddit Community</Button></Link>
            </Col>
        </Row>

      </Container>
);
};
 

export default Footer;

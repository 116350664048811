import { Avatar, Button, Link, Navbar, Switch, Text, Badge, Row } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { SiChatbot as SiteLogo } from 'react-icons/si'
import { useTheme as useNextTheme } from "next-themes";
import { useTheme } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { getAvatarUrl } from "@/libs/utils";



export const NavigationBar = () => {
  const { name,  avatar_url } = useSelector((state) => state?.user?.profile || {});
  const { setTheme } = useNextTheme();
  const { isDark } = useTheme();
  const navigate = useNavigate();
  

  const { register, isAuthenticated, login, isLoading, logout } = useKindeAuth();


  return (
    <Navbar
      isBordered
      isCompact
      //shouldHideOnScroll
      css={{
        overflow: "hidden",
        "& .nextui-navbar-container": {
          background: "$background",
          borderBottom: "none",
        },
      }}
    >
      <Navbar.Brand>
        <Link color="text" onClick={() => navigate("/")}>
          <SiteLogo size={20} />
          <Text size={20} b color="inherit" css={{ ml: "5px" }}>
            SPICYCHAT
          </Text>
          <Text size={20} b hideIn="xs" css={{
            textGradient: "45deg, $blue600 -20%, $blue400 50%",
          }}>.AI</Text>
          <Badge size="xs" variant="flat" color="secondary" css={{ '@xsMax': { display: 'none' } }}>Admin</Badge>

        </Link>

      </Navbar.Brand>
      <Navbar.Content hideIn="xs" css={{ pl: "6rem" }}>
      </Navbar.Content>
      <Navbar.Collapse>

        {isAuthenticated && <Navbar.CollapseItem>
          <Link color="text">
            {name && <Avatar squared text={name} size="md" src={avatar_url ? getAvatarUrl(avatar_url) : null} />}
          </Link>
        </Navbar.CollapseItem>}
        {!isAuthenticated && <Navbar.CollapseItem>
          <Link color="text" onClick={login}>
            Login
          </Link>
        </Navbar.CollapseItem>}
        {!isAuthenticated && <Navbar.CollapseItem>
          <Link color="text" onClick={register}>
            Register
          </Link>
        </Navbar.CollapseItem>}
        <Navbar.CollapseItem>
          <Switch
            size="xs"
            checked={isDark}
            onChange={(e) => setTheme(e.target.checked ? "dark" : "light")}
          />
        </Navbar.CollapseItem>
      </Navbar.Collapse>
      <Navbar.Content align="flex-end" gap={1}>
        <Row justify="flex-end">


        {!isLoading && <>
            {isAuthenticated &&
              <>
                <Navbar.Item hideIn="xs">
                  <Link color="text" onClick={() => logout()}>
                    {name && <Avatar squared text={name} size="md" src={avatar_url ? getAvatarUrl(avatar_url,{
                    width: 48,
                    height: 48,
                    focus: "top",
                  }) : null} />}
                  </Link>
                </Navbar.Item>
              </>}
          </>}
          {!isAuthenticated && <>
            <Navbar.Item hideIn={"xs"} css={{ mr: "10px" }}>
              <Button auto flat onPress={login}>
                Login
              </Button>
            </Navbar.Item>
            <Navbar.Item hideIn={"xs"}>
              <Button auto flat onPress={register}>
                Signup
              </Button>
            </Navbar.Item>
          </>}
          <Navbar.Item hideIn={"xs"}>
            <Switch
              size="xs"
              checked={isDark}
              onChange={(e) => setTheme(e.target.checked ? "dark" : "light")}
            />
          </Navbar.Item>
        </Row>
      </Navbar.Content>
      <Navbar.Toggle aria-label="toggle navigation" showIn="xs" />
    </Navbar>
  );
};

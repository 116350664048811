import { useState , useEffect} from "react";
import PropTypes from "prop-types";
import {ImArrowUp2 as TopArrowIcon} from "react-icons/im";
import {Button} from "@nextui-org/react";

function scrollToTop(smooth = false) {
  if (smooth) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    document.documentElement.scrollTop = 0;
  }
}

const ScrollBackToTop = ({top=1000,smooth=true}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setVisible(document.documentElement.scrollTop >= top);
    };
    onScroll();
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, [top]);

  return  (
    <>
    {visible && <Button auto size="xs" color="secondary"css={{position:"fixed", bottom:"20px", right:"20px", zIndex:"1000"}}
          onPress={() => scrollToTop(smooth)}>
          <TopArrowIcon />
        </Button>
    }
    </>
  )
  
}

ScrollBackToTop.propTypes = {
  top: PropTypes.number,
  smooth: PropTypes.bool,
};
export default ScrollBackToTop;